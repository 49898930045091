import React from 'react'
import ReactDOM from 'react-dom'
import './main.css'
import '@/style/main.css'
import App from './App.jsx'
import Init from './Init.jsx'
import './polyfill.mjs'

ReactDOM.render(
    <React.StrictMode>
        <Init>
            <App />
        </Init>
    </React.StrictMode>,

    document.getElementById('root')
)
