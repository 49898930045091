import {useEffect, useState} from 'react'
import {initToken} from './biz/token.mjs'

export default function Init({children}) {
    const [isReady, setIsReady] = useState(false)

    useEffect(
        () => {
            (async () => {
                await Promise.all([
                    initToken()
                ])

                setIsReady(true)
            })()
        },

        []
    )

    if (isReady) {
        return children
    }
    else {
        return null
    }
}
